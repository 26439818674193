<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col md="12">
          <v-card class="mb-6 card-shadow border-radius-xl py-4">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-select
                    v-model="selectedQuery"
                    :items="availableQueries"
                    item-text="name"
                    item-value="id"
                    :hint="selectedQuery ? selectedQuery.description : ''"
                    persistent-hint
                    label="選擇查詢項目"
                    :disabled="isActionDisabled"
                    :no-data-text="$t(`common['No Data']`)"
                    return-object
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-for="(field, index) in inputFields"
                  :key="'field_' + index"
                  cols="12"
                  sm="4"
                  md="2"
                >
                  <v-menu
                    v-if="field.type === 'Date' && field.key === 'from'"
                    v-model="showStartPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    name="startDate"
                    :disabled="isLoading"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="startDate"
                        label="開始日期"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      color="blue-grey lighten-1"
                      @input="showStartPicker = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-else-if="field.type === 'Date' && field.key === 'to'"
                    v-model="showEndPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    name="endDate"
                    :disabled="isLoading"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="endDate"
                        label="結束日期"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      color="blue-grey lighten-1"
                      @input="showEndPicker = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-else-if="field.type === 'Date'"
                    v-model="showStartPicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    name="startDate"
                    :disabled="isLoading"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="scope[field.key]"
                        :label="field.label"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="scope[field.key]"
                      color="blue-grey lighten-1"
                      @input="showStartPicker = false"
                      :allowed-dates="allowedDates"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    v-else-if="field.type === 'Number'"
                    :label="field.label"
                    v-model.number="scope[field.key]"
                    type="number"
                    min="1"
                    :rules="numberRules"
                    :disabled="isLoading"
                  />
                  <v-text-field
                    v-else
                    :label="field.label"
                    v-model.trim="scope[field.key]"
                    :type="field.type"
                    :disabled="isLoading"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <div>
                    <v-btn
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-primary
                        bg-gradient-secondary
                        py-3
                        px-6
                        ms-3
                      "
                      @click="
                        pagination.page = 1;
                        handleButtonClick('search');
                      "
                      :disabled="isLoading"
                      :loading="isLoading"
                    >
                      {{ $t('common["Search"]') }}</v-btn
                    >
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="2"
                  class="d-flex align-center"
                  :class="{
                    'pt-5': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
                  }"
                >
                  <v-btn
                    class="
                      font-weight-normal
                      text-capitalize
                      btn-primary
                      bg-gradient-warning
                      py-3
                      px-6
                      ms-3
                    "
                    @click="handleButtonClick('export')"
                    :disabled="isLoading || isDownloading"
                    :loading="isDownloading"
                  >
                    匯出查詢結果
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              查詢結果
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="fa-search"
                :label="$t(`common['Search']`)"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="tableHeaders"
                :items="tableData"
                :loading="isLoading"
                :loading-text="$t(`common['Loading... Please wait']`)"
                :no-data-text="$t(`common['No Data']`)"
                :search="search"
                :footer-props="{
                  'items-per-page-text': $t(`common['Rows per page']`),
                }"
                :page.sync="pagination.page"
                hide-default-footer
                @page-count="pagination.pageCount = $event"
                :items-per-page="pagination.itemsPerPage"
              >
              </v-data-table>
            </v-card-text>
            <v-card-actions class="card-padding">
              <Pagination
                :pagination="pagination"
                :total="tableData.length"
                :loading="isLoading"
                :show-items-per-page="true"
              ></Pagination>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Pagination from "../Campaigns/Widgets/Pagination.vue";
import { fetchQueryPresets } from "src/apis/fetchData";
import { executeQueryPreset, exportQueryPreset } from "src/apis/updateData";

export default {
  name: "DataQuery",
  components: { Pagination },
  props: {},
  data() {
    return {
      moment: moment,
      availableQueries: [],
      selectedQuery: null,
      isQueriesLoaded: false,
      isLoading: false,
      isDownloading: false,
      showStartPicker: false,
      showEndPicker: false,
      startDate: null,
      endDate: null,
      search: null,
      tableData: [],
      tableHeaders: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      numberRules: [
        (v) =>
          v === undefined ||
          v === "" ||
          this.isValidNumber(v) ||
          "數值須為正整數",
      ],
      scope: {},
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      permissions: (state) => state.userPermissions,
    }),
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    isActionDisabled() {
      return !this.isQueriesLoaded;
    },
    inputFields() {
      return this.selectedQuery?.parameters || [];
    },
    hasFromToParams() {
      const hasFromParam = this.inputFields.find(
        (field) => field.key === "from"
      );
      const hasToParam = this.inputFields.find((field) => field.key === "to");
      return hasFromParam && hasToParam;
    },
  },
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
      }
    });
  },
  mounted() {
    this.startDate = moment().subtract(1, "month").format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    if (this.isLoggedIn) {
      this.getQueries();
    }
  },
  watch: {
    selectedQuery() {
      this.scope = {};
    },
  },
  methods: {
    getQueries() {
      fetchQueryPresets()
        .then((res) => {
          console.log("fetchQueryPresets done", res);
          this.availableQueries = res.data;
        })
        .catch((e) => {
          console.log("fetchQueryPresets failed", e);
        })
        .finally(() => {
          this.isQueriesLoaded = true;
        });
    },
    getResults(data) {
      this.isLoading = true;
      executeQueryPreset(this.selectedQuery.id, data)
        .then((res) => {
          this.tableData = res.data?.rows || [];
          
          this.tableHeaders =
            Object.entries(res.data?.headers).map(([key, value]) => ({
              text: value,
              sortable: true,
              value: key,
            })) || [];
        })
        .catch((e) => {
          console.error("executeQueryPreset failed", e);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    exportResults(data) {
      this.isDownloading = true;
      exportQueryPreset(this.selectedQuery.id, data)
        .then((res) => {
          console.log("exportQueryPreset done", res);
          if (!res.data) throw new Error("No Data");

          const fileURL = window.URL.createObjectURL(new Blob([res.data]));
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `${this.selectedQuery.name}-${new Date().getTime()}.xlsx`
          );
          document.body.appendChild(fileLink);

          fileLink.click();
          window.URL.revokeObjectURL(fileLink);
        })
        .catch((e) => {
          console.log("exportQueryPreset failed", e);
          let errmsg = "無法匯出查詢結果，請稍後重試";
          if (e.response?.data?.message) {
            errmsg += `<br/>(${e.response.data.message})`;
          }
          this.showErrorAlert(errmsg);
        })
        .finally(() => {
          this.isDownloading = false;
        });
    },
    isValidNumber(value) {
      return Number.isInteger(value) && value > 0;
    },
    validateNumberField() {
      return Object.values(this.scope).every((value) =>
        typeof value === "number" ? this.isValidNumber(value) : true
      );
    },
    formatDateFieldToTs(scope) {
      this.inputFields.forEach(({ key, type }) => {
        if (scope[key] && type === "Date") {
          scope = { ...scope, [key]: moment(scope[key]).unix() };
        }
      });
      return scope;
    },
    handleButtonClick(action) {
      if (!this.selectedQuery) return this.showErrorAlert("請選擇查詢項目");

      const isValidNumber = this.validateNumberField();
      if (!isValidNumber)
        return this.showErrorAlert("請確認欄位數值是否填寫正確");

      if (this.hasFromToParams) {
        const start = moment(this.startDate);
        let end = moment(this.endDate);
        if (end.diff(start, "days") < 0)
          return this.showErrorAlert("開始日期不得大於結束日期");

        this.scope.from = start;
        this.scope.to = moment(end.format("YYYY-MM-DD") + " 23:59:59");
      }
      const formattedScope = this.formatDateFieldToTs(this.scope);

      const data = {
        scope: formattedScope,
      };

      if (action === "search") {
        this.getResults(data);
      } else if (action === "export") {
        this.exportResults(data);
      }
    },
    showErrorAlert(message) {
      this.$swal({
        html: message,
        type: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
    allowedDates: (v) => new Date(v) <= Date.now(),
  },
};
</script>
