var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mb-6 card-shadow border-radius-xl py-4"},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{attrs:{"items":_vm.availableQueries,"item-text":"name","item-value":"id","hint":_vm.selectedQuery ? _vm.selectedQuery.description : '',"persistent-hint":"","label":"選擇查詢項目","disabled":_vm.isActionDisabled,"no-data-text":_vm.$t("common['No Data']"),"return-object":""},model:{value:(_vm.selectedQuery),callback:function ($$v) {_vm.selectedQuery=$$v},expression:"selectedQuery"}})],1)],1),_c('v-row',_vm._l((_vm.inputFields),function(field,index){return _c('v-col',{key:'field_' + index,attrs:{"cols":"12","sm":"4","md":"2"}},[(field.type === 'Date' && field.key === 'from')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"startDate","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"開始日期","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},on))]}}],null,true),model:{value:(_vm.showStartPicker),callback:function ($$v) {_vm.showStartPicker=$$v},expression:"showStartPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.showStartPicker = false}},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):(field.type === 'Date' && field.key === 'to')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"endDate","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"結束日期","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},on))]}}],null,true),model:{value:(_vm.showEndPicker),callback:function ($$v) {_vm.showEndPicker=$$v},expression:"showEndPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.showEndPicker = false}},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1):(field.type === 'Date')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","name":"startDate","disabled":_vm.isLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":field.label,"readonly":""},model:{value:(_vm.scope[field.key]),callback:function ($$v) {_vm.$set(_vm.scope, field.key, $$v)},expression:"scope[field.key]"}},on))]}}],null,true),model:{value:(_vm.showStartPicker),callback:function ($$v) {_vm.showStartPicker=$$v},expression:"showStartPicker"}},[_c('v-date-picker',{attrs:{"color":"blue-grey lighten-1","allowed-dates":_vm.allowedDates},on:{"input":function($event){_vm.showStartPicker = false}},model:{value:(_vm.scope[field.key]),callback:function ($$v) {_vm.$set(_vm.scope, field.key, $$v)},expression:"scope[field.key]"}})],1):(field.type === 'Number')?_c('v-text-field',{attrs:{"label":field.label,"type":"number","min":"1","rules":_vm.numberRules,"disabled":_vm.isLoading},model:{value:(_vm.scope[field.key]),callback:function ($$v) {_vm.$set(_vm.scope, field.key, _vm._n($$v))},expression:"scope[field.key]"}}):_c('v-text-field',{attrs:{"label":field.label,"type":field.type,"disabled":_vm.isLoading},model:{value:(_vm.scope[field.key]),callback:function ($$v) {_vm.$set(_vm.scope, field.key, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"scope[field.key]"}})],1)}),1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",class:{
                  'pt-5': _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg,
                },attrs:{"cols":"12","sm":"2"}},[_c('div',[_c('v-btn',{staticClass:"\n                      font-weight-normal\n                      text-capitalize\n                      btn-primary\n                      bg-gradient-secondary\n                      py-3\n                      px-6\n                      ms-3\n                    ",attrs:{"disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":function($event){_vm.pagination.page = 1;
                      _vm.handleButtonClick('search');}}},[_vm._v(" "+_vm._s(_vm.$t('common["Search"]')))])],1)]),_c('v-col',{staticClass:"d-flex align-center",class:{
                  'pt-5': _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.lg,
                },attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"\n                    font-weight-normal\n                    text-capitalize\n                    btn-primary\n                    bg-gradient-warning\n                    py-3\n                    px-6\n                    ms-3\n                  ",attrs:{"disabled":_vm.isLoading || _vm.isDownloading,"loading":_vm.isDownloading},on:{"click":function($event){return _vm.handleButtonClick('export')}}},[_vm._v(" 匯出查詢結果 ")])],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 查詢結果 "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.$t("common['Search']"),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.isLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"search":_vm.search,"footer-props":{
                'items-per-page-text': _vm.$t("common['Rows per page']"),
              },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}}})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.tableData.length,"loading":_vm.isLoading,"show-items-per-page":true}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }